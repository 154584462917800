import { PaymentCardData } from "../store/reducers/paymentMethods/paymentMethodsSlice";
import { useTranslation } from "react-i18next";

const useCardFormat = () => {
  const { t } = useTranslation();
  const stripXCardNumber = (paymentMethod: PaymentCardData) =>
    paymentMethod.card_number.slice(-4);

  const cardExpiry = (paymentMethod: PaymentCardData) =>
    t("payment_methods.expiry") + " " + paymentMethod.month + "/" + paymentMethod.year;

  return {
    lastDigitsCardNumber: stripXCardNumber,
    cardExpiry,
  };
};

export default useCardFormat;
