import i18next from "i18next";
import React from "react";

const LanguageChanger = () => {
  const languages = i18next.languages;

  const [show, setShow] = React.useState(false);

  const languageLabel = (langCountry: string) => {
    const lang = langCountry.split("-")[0];

    switch (lang) {
      case "en":
        return "English";
      case "da":
        return "Dansk";
      case "nb":
        return "Norsk Bokmål";
      default:
        return lang;
    }
  };

  const languageFlag = (langCountry: string) => {
    const lang = langCountry.split("-")[0];
    return `/images/flags/${lang}.svg`;
  };

  return (
    <div className={"relative text-sm"}>
      <button
        onClick={() => setShow(!show)}
        className={"flex gap-x-1 items-center text-gray-400"}
      >
        <img
          className={"w-5 h-4 rounded-md"}
          src={`/images/flags/${i18next.language}.svg`}
          alt={`${i18next.language} flag`}
        />
        {languageLabel(i18next.language)}
      </button>
      {show && (
        <ul
          className={
            "bg-white rounded-2xl shadow px-3 py-2 absolute top-6 right-0 w-[170px] space-y-2"
          }
        >
          {languages
            .filter((lang) => lang !== i18next.language)
            .map((lang) => (
              <li key={lang}>
                <button
                  className={"flex gap-x-1 items-center"}
                  onClick={() => {
                    setShow(false);
                    i18next.changeLanguage(lang);
                  }}
                >
                  <img
                    className={"w-5 h-4 rounded-md"}
                    src={languageFlag(lang)}
                    alt={`${lang} flag`}
                  />
                  {languageLabel(lang)}
                </button>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageChanger;
