import { useTranslation } from "react-i18next";

interface Props {
  value?: number;
  setValue?: (value: number) => void;
}

const QuantitySelector = ({ value = 1, setValue = () => {} }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="">
      <select
        className={
          "rounded-md h-10 border-gray-300 focus:ring-0 focus:border-black px-4 py-2 pr-8 placeholder-gray-500"
        }
        value={value}
        onChange={(e) => setValue(parseInt(e.target.value))}
      >
        <option value="1">{t("checkout.qty")} 1</option>
        {[...Array(99)].map((_, i) => (
          <option key={"option-" + i} value={i + 2}>
            {t("checkout.qty")} {i + 2}
          </option>
        ))}
      </select>
    </div>
  );
};

export default QuantitySelector;
