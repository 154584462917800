import { Navigate, Outlet } from "react-router-dom";
import { HorizontalRule, PageTitle, Paragraph } from "../../ui/Fonts";
import PoweredBy from "../../ui/PoweredBy";
import { useMoneyFormat } from "../../../hooks/useMoneyFormat";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getAccountCode,
  hasAccount,
  getPermissions,
} from "../../../store/reducers/auth/authSlice";
import React, { useEffect } from "react";
import { fetchAccount } from "../../../store/reducers/account/accountSlice";
import LoadingStates from "../../ui/LoadingStates";
import useBalanceColor from "../../../hooks/useBalanceColor";
import PaymentMethods from "../../ui/PaymentMethods";
import Subscriptions from "../../ui/Subscriptions";
import BillingHistory from "../../ui/BillingHistory";
import clsx from "clsx";
import usePermissions from "../../../hooks/usePermissions";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import LanguageChanger from "../../ui/LanguageChanger";

const DashboardView = () => {
  const accountIsReady = useAppSelector(hasAccount);
  const { formatMoney } = useMoneyFormat();
  const dispatch = useAppDispatch();
  const accountCode = useAppSelector(getAccountCode);
  const { t } = useTranslation();

  const status = useAppSelector((state) => state.account.status);
  const error = useAppSelector((state) => state.account.error);
  const account = useAppSelector((state) => state.account.account);
  const balance = formatMoney(account?.balance || 0);
  const balanceColor = useBalanceColor(account?.balance || 0);
  const permissions = useAppSelector(getPermissions);
  const { userCan } = usePermissions(permissions);

  useEffect(() => {
    console.log("account", account);
    if (!account) {
      dispatch<any>(fetchAccount(accountCode));
    }
  }, [account, accountCode, dispatch]);

  if (!accountIsReady) {
    return <Navigate to={"/create-account"} />;
  }

  return (
    <>
      <Outlet />
      <div className={"flex flex-wrap justify-between -mx-4"}>
        <div className={"w-full md:w-1/2 px-4"}>
          {status === "loading" && <LoadingStates count={1} height={25} />}
          {error && <p>{error}</p>}
          {status === "succeeded" && (
            <PageTitle className={"mb-0.5"}>{account?.company_name}</PageTitle>
          )}
          <Paragraph className={"mb-0"}>
            {t("dashboard.manage_billings")}
          </Paragraph>
        </div>
        <div className={"w-full md:w-1/2  px-4 flex md:justify-end"}>
          <div>
            <Paragraph className={"mb-0 md:text-right"}>
              {t("dashboard.balance")}
            </Paragraph>
            {status === "loading" && <LoadingStates count={1} height={25} />}
            {status === "succeeded" && (
              <PageTitle className={clsx("mb-0.5 md:text-right", balanceColor)}>
                {balance}
              </PageTitle>
            )}
          </div>
        </div>
      </div>
      <HorizontalRule />
      <div className={"flex flex-wrap -mx-4 lg:-mx-6"}>
        {userCan("subscription_display") && (
          <div className={"w-full md:w-1/2 px-4 lg:px-6"}>
            <Subscriptions />
          </div>
        )}
        {userCan("payment_card_display") && (
          <div className={"w-full md:w-1/2 px-4 lg:px-6"}>
            <PaymentMethods />
          </div>
        )}
      </div>
      <HorizontalRule />
      {userCan("invoices_display") && <BillingHistory />}
      <div className="flex justify-between gap-x-4 mt-4 ">
        <PoweredBy />
        <LanguageChanger />
      </div>
    </>
  );
};

export default DashboardView;
