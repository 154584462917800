import GuestLayout from "../../layout/GuestLayout";
import { HorizontalRule, PageTitle, Paragraph } from "../../ui/Fonts";
import Envelope from "../../ui/icons/Envelope";
import Button from "../../ui/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useSendAuthEmail from "../../../hooks/useSendAuthEmail";
import toast from "react-hot-toast";
import Toast from "../../ui/Toast";
import GoBackLink from "../../ui/GoBackLink";
import { useTranslation } from "react-i18next";

const CheckEmailView = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const emailParam = searchParams.get("email") || "";
  const [email, setEmail] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    return setEmail(emailParam);
  }, [emailParam]);

  const sendAuthEmail = useSendAuthEmail();

  const sendEmailAgain = async () => {
    await sendAuthEmail(email).then(() => {
      const text = t("verify_email.email_sent_notification", {
        email: email,
      });
      toast.custom((t) => <Toast message={text} />, {
        duration: 2000,
      });
    });
  };

  return (
    <GuestLayout>
      <div className={"text-center"}>
        <HorizontalRule />
        <div className={"text-center space-y-4"}>
          <div className={"flex justify-center"}>
            <Envelope />
          </div>
          <PageTitle>{t("verify_email.title")}</PageTitle>
          <Paragraph>
            {t("verify_email.send_message")} <br />
            <strong>{email}</strong>
          </Paragraph>
          {
            // todo open email client logic here
          }
          <div className={"py-4"}>
            <Button block={true} href={"mailto:"}>
              {t("verify_email.open_email_app")}
            </Button>
          </div>
          <Paragraph>
            {t("verify_email.didnt_receive_email")}{" "}
            <button
              onClick={() => sendEmailAgain()}
              className={"font-medium text-gray-900"}
            >
              {t("verify_email.resend_action")}
            </button>
          </Paragraph>
          <GoBackLink onClick={() => navigate("/login")}>
            {t("verify_email.back_to_previous")}
          </GoBackLink>
        </div>
      </div>
    </GuestLayout>
  );
};

export default CheckEmailView;
