import { HorizontalRule, Paragraph } from "./Fonts";
import PoweredBy from "./PoweredBy";
import Button from "./Button";
import { useMoneyFormat } from "../../hooks/useMoneyFormat";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getStatus } from "../../store/reducers/plans/plansSlice";
import LoadingStates from "./LoadingStates";
import {
  AccountStep,
  getSelectedPlanName,
  getSelectedPlanTerm,
  getTotal,
  planTermIsSelected,
  setQuantity,
  setCheckoutStep,
  setIsSubmitting,
  canCheckout,
  creditCardDataToStripe,
} from "../../store/reducers/checkout/checkoutSlice";
import QuantitySelector from "./QuantitySelector";
import {
  getAccountCode,
  getCompanyName,
  getStripeToken,
  getToken,
  setToken,
} from "../../store/reducers/auth/authSlice";
import { useEffect, useState } from "react";
import createNewAccount from "../../services/CreateAccountService";
import { useNavigate, useParams } from "react-router-dom";
import {
  editSubscription,
  newSubscription,
  resetCreateSubscriptionStatus,
  resetEditSubscriptionStatus,
} from "../../store/reducers/subscriptions/subscriptionSlice";
import { fetchAccount } from "../../store/reducers/account/accountSlice";
import ErrorMessage from "./ErrorMessage";
import { useTranslation } from "react-i18next";
import useFormats from "../../hooks/useFormats";

interface Props {
  step?: AccountStep;
}

const CheckoutBox = ({ step }: Props) => {
  const { t } = useTranslation();
  const { formatMoney } = useMoneyFormat();
  const currency = useAppSelector((state) => state.plans.currency);
  const plansStatus = useAppSelector(getStatus);
  const isLoading = plansStatus === "loading";
  const total = useAppSelector(getTotal);
  const planIsSelected = useAppSelector(planTermIsSelected);
  const isSubmitting = useAppSelector((state) => state.checkout.isSubmitting);
  const selectedPlan = useAppSelector(getSelectedPlanTerm);
  const selectedPlanName = useAppSelector(getSelectedPlanName);
  const accountName = useAppSelector(getCompanyName);
  const accountData = useAppSelector((state) => state.checkout.accountData);
  const creditCardData = useAppSelector(creditCardDataToStripe);
  const checkoutIsValid = useAppSelector(canCheckout);
  const quantity = useAppSelector((state) => state.checkout.quantity);
  const accountCode = useAppSelector(getAccountCode);
  const token = useAppSelector(getToken);
  const stripeToken = useAppSelector(getStripeToken);
  const checkoutStep = useAppSelector((state) => state.checkout.checkoutStep);

  const account = useAppSelector((state) => state.account.account);
  const { uuid } = useParams();
  const error = useAppSelector((state) => state.subscriptions.error);
  const createStatus = useAppSelector(
    (state) => state.subscriptions.createStatus
  );
  const updateStatus = useAppSelector(
    (state) => state.subscriptions.updateStatus
  );
  const { planTermsBillingInterval } = useFormats();

  const [createAccountError, setCreateAccountError] = useState<string | null>(
    null
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedPlan) {
      dispatch({ type: "plans/setCurrency", payload: selectedPlan?.currency });
    }
  }, [dispatch, selectedPlan]);

  useEffect(() => {
    if (!account && accountCode) {
      dispatch<any>(fetchAccount(accountCode));
    }
  }, [account, accountCode, dispatch]);

  const navigate = useNavigate();

  useEffect(() => {
    if (step === "create-plan" && isSubmitting) {
      dispatch<any>(
        newSubscription({
          account: account?.code || "",
          terms: selectedPlan?.uuid || "",
          quantity: quantity,
          collection_method: "payment_card",
        })
      );
    } else if (step === "edit-plan" && isSubmitting) {
      dispatch<any>(
        editSubscription({
          uuid: uuid || "",
          subscription: {
            terms: selectedPlan?.uuid || "",
            quantity: quantity || 1,
            change_type: "immediate",
          },
        })
      );
    }
  }, [account, dispatch, isSubmitting, quantity, selectedPlan?.uuid, step]);

  useEffect(() => {
    if (createStatus === "succeeded" || updateStatus === "succeeded") {
      dispatch(resetEditSubscriptionStatus());
      dispatch(resetCreateSubscriptionStatus());
      navigate("/");
    }
  });

  useEffect(() => {
    if (createAccountError) {
      dispatch(setIsSubmitting(false));
      dispatch(setCheckoutStep("checkout"));
    }
  }, [createAccountError, dispatch]);

  useEffect(() => {
    if (checkoutIsValid && checkoutStep === "plans") {
      console.log("checkoutIsValid", checkoutIsValid);
      setCreateAccountError(null);
      dispatch<any>(setCheckoutStep("submitting"));

      createNewAccount({
        account: accountData,
        plan: selectedPlan,
        quantity,
        stripe_token: stripeToken || "",
        card_data: creditCardData,
      })
        .then((r) => {
          dispatch(setToken(localStorage.getItem("token")));
        })
        .catch((e) => {
          setCreateAccountError(e);
          dispatch(setIsSubmitting(false));
          dispatch({
            type: "checkout/resetCheckout",
          });
          dispatch(setCheckoutStep("plans"));
        });
    }
  }, [
    accountData,
    checkoutIsValid,
    checkoutStep,
    creditCardData,
    dispatch,
    isSubmitting,
    quantity,
    selectedPlan,
    stripeToken,
  ]);

  const checkout = () => {
    dispatch(setIsSubmitting(true));

    setTimeout(() => {
      dispatch(setIsSubmitting(false));
    }, 10);
  };

  if (isLoading) {
    return <LoadingStates count={1} height={250} />;
  }

  return (
    <>
      <div className={"bg-gray-100 p-4 lg:p-8 rounded-lg"}>
        <Paragraph className={"mb-2"}>{t("checkout.title")}</Paragraph>
        <h2
          className={
            "text-3xl sm:text-4xl md:text-4xl font-medium text-gray-900"
          }
        >
          {formatMoney(total, currency || "DKK")}
        </h2>
        <HorizontalRule />
        {selectedPlan && (
          <div className={"flex justify-between"}>
            <div className={"mb-4 flex items-center"}>
              {(step === "plans" ||
                step === "create-plan" ||
                step === "edit-plan") && (
                <div className={"mr-2"}>
                  <QuantitySelector
                    value={quantity}
                    setValue={(value) => dispatch<any>(setQuantity(value))}
                  />
                </div>
              )}
              <Paragraph className={"!mb-0 !text-base"}>
                {step === "create-account" && quantity + "x "}
                {selectedPlanName} -{" "}
                {planTermsBillingInterval(selectedPlan).toLowerCase()}{" "}
              </Paragraph>
            </div>
            <div className={"text-right"}>
              <Paragraph className={"!mb-0 !text-base"}>
                {
                  (selectedPlan &&
                    formatMoney(parseFloat(selectedPlan?.price) * quantity),
                  currency || "DKK")
                }
              </Paragraph>
            </div>
          </div>
        )}

        {step === "plans" && (
          <div>
            <Button
              disabled={!planIsSelected}
              onClick={() => navigate("/create-account/checkout")}
              block={true}
            >
              {t("common.continue")}
            </Button>
          </div>
        )}
        {step === "create-plan" && (
          <div>
            <Button
              disabled={!planIsSelected || createStatus === "loading"}
              loading={createStatus === "loading"}
              onClick={() => checkout()}
              block={true}
            >
              {t("common.subscribe")}
            </Button>
          </div>
        )}
        {step === "edit-plan" && (
          <div>
            <Button
              disabled={!planIsSelected || updateStatus === "loading"}
              onClick={() => checkout()}
              loading={updateStatus === "loading"}
              block={true}
            >
              {t("checkout.update_plan")}
            </Button>
          </div>
        )}
        {step === "create-account" && (
          <div>
            <Button
              disabled={isSubmitting}
              onClick={() => checkout()}
              block={true}
            >
              {t("common.subscribe")}
            </Button>
            <Paragraph className={"mt-4 text-center"}>
              {t("checkout.consent", {
                account: accountName,
              })}
            </Paragraph>
          </div>
        )}
        {error && <ErrorMessage message={error} />}
        {createAccountError && <ErrorMessage message={createAccountError} />}
      </div>
      <div className={"flex justify-end"}>
        <PoweredBy />
      </div>
    </>
  );
};

export default CheckoutBox;
