import { useNavigate } from "react-router-dom";
import { Paragraph } from "./Fonts";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  children?: ReactNode;
  onClick?: () => void;
}

const GoBackLink = ({ children, onClick = () => {} }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <button
      onClick={onClick || goBack}
      className={"inline-flex items-center justify-center"}
    >
      <ChevronLeftIcon className={"w-4 h-4 text-gray-400 mr-1"} />
      <Paragraph className={"!mb-0"}>
        {children || t("common.go_back")}
      </Paragraph>
    </button>
  );
};

export default GoBackLink;
