import Popup from "../../layout/Popup";
import { HorizontalRule, PageTitle, Paragraph } from "../../ui/Fonts";
import PaymentCardForm from "../../layout/forms/PaymentCardForm";
import ButtonGroup from "../../layout/ButtonGroup";
import Button from "../../ui/Button";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useEffect } from "react";
import {
  creditCardDataToStripe,
  setIsSubmitting,
} from "../../../store/reducers/checkout/checkoutSlice";
import ErrorMessage from "../../ui/ErrorMessage";
import { createPaymentCard } from "../../../store/reducers/paymentMethods/paymentMethodsSlice";
import { getStripeToken } from "../../../store/reducers/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NewPaymentMethod = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const stripeToken = useAppSelector(getStripeToken);
  const isSubmitting = useAppSelector((state) => state.checkout.isSubmitting);
  const accountUuid = useAppSelector((state) => state.account.account?.uuid);
  const creditCardData = useAppSelector(creditCardDataToStripe);
  const error = useAppSelector((state) => state.paymentMethods.error);
  const status = useAppSelector((state) => state.paymentMethods.createStatus);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "checkout/resetCheckout" });
  }, []);

  useEffect(() => {
    if (creditCardData && isSubmitting) {
      dispatch<any>(
        createPaymentCard({
          account_id: accountUuid || "",
          stripe_token: stripeToken || "",
          card_data: creditCardData,
        })
      );
      dispatch<any>(setIsSubmitting(false));
    }
  }, [creditCardData, dispatch, isSubmitting]);

  useEffect(() => {
    if (status === "succeeded") {
      navigate("/");
    }
  }, [status]);

  const submitForm = () => {
    dispatch<any>(setIsSubmitting(true));
  };

  return (
    <Popup>
      <PageTitle>{t("payment_methods.add_payment_method")}</PageTitle>
      <Paragraph>{t("payment_methods.add_additional")}</Paragraph>
      <HorizontalRule />
      <PaymentCardForm />
      <ButtonGroup className={"justify-end"}>
        <Button href={"/"} variant={"secondary"}>
          {t("common.cancel")}
        </Button>
        <Button
          onClick={submitForm}
          loading={status === "loading"}
          disabled={status === "loading"}
        >
          {t("payment_methods.add_payment_method")}
        </Button>
      </ButtonGroup>
      {error && <ErrorMessage message={error} />}
    </Popup>
  );
};

export default NewPaymentMethod;
