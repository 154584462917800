import { ReactNode } from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import ApplicationSkeleton from "./ApplicationSkeleton";
import Container from "./Container";
import Logo from "../ui/Logo";
import ButtonGroup from "./ButtonGroup";
import Button from "../ui/Button";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  hasValidToken,
  hasAccount,
  signOut,
  getPermissions,
} from "../../store/reducers/auth/authSlice";
import LogoutIcon from "../ui/icons/LogoutIcon";
import PencilIcon from "../ui/icons/PencilIcon";
import usePermissions from "../../hooks/usePermissions";
import CurrencySelector from "../ui/CurrencySelector";
import router from "../../routes";
import { useTranslation } from "react-i18next";

interface Props {
  children?: ReactNode;
}

const AuthLayout = ({ children }: Props) => {
  const accountIsReady = useAppSelector(hasAccount);
  const isLoggedIn = useAppSelector(hasValidToken);
  const dispatch = useAppDispatch();
  const permissions = useAppSelector(getPermissions);
  const { userCan } = usePermissions(permissions);
  const { t } = useTranslation();

  const queryParameters = new URLSearchParams(window.location.search);
  const code = queryParameters.get("code");

  const location = useLocation();

  const isHome = location.pathname === "/";

  if (code) {
    return <Navigate to={`/validate/${code}`} />;
  }

  if (!isLoggedIn) {
    return <Navigate to={"/login"} />;
  }

  return (
    <>
      <ApplicationSkeleton>
        <Container>
          <div className={"mb-12 flex justify-between"}>
            <Logo />

            <div className={"flex justify-end items-center space-x-4"}>
              <CurrencySelector />
              <ButtonGroup>
                {accountIsReady && userCan("account_update") && isHome && (
                  <Button
                    href={"/edit-account"}
                    icon={<PencilIcon className={"w-4 h-4"} />}
                    variant={"secondary"}
                  >
                    {t("account.edit")}
                  </Button>
                )}
                <Button
                  onClick={() => dispatch(signOut())}
                  icon={<LogoutIcon className={"w-4 h-4"} />}
                >
                  {t("account.logout")}
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <Outlet />
        </Container>
      </ApplicationSkeleton>
    </>
  );
};

export default AuthLayout;
