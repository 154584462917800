import Button from "./Button";
import PlusIcon from "./icons/PlusIcon";
import ActionPanel from "./ActionPanel";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import React, { useEffect } from "react";
import { fetchSubscriptions } from "../../store/reducers/subscriptions/subscriptionSlice";
import LoadingStates from "./LoadingStates";
import ActionTile from "./ActionTile";
import useSubscriptionFormat from "../../hooks/useSubscriptionFormat";
import { getPermissions } from "../../store/reducers/auth/authSlice";
import usePermissions from "../../hooks/usePermissions";
import { useTranslation } from "react-i18next";

const Subscriptions = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const permissions = useAppSelector(getPermissions);
  const { userCan } = usePermissions(permissions);
  const { subscriptionTitle, subscriptionDescription, subscriptionTag } =
    useSubscriptionFormat();
  const subscriptions = useAppSelector(
    (state) => state.subscriptions.subscriptions
  );
  const status = useAppSelector((state) => state.subscriptions.status);

  useEffect(() => {
    if (!subscriptions) {
      dispatch<any>(fetchSubscriptions());
    }
  }, [dispatch, subscriptions]);
  return (
    <>
      <ActionPanel
        title={t("subscriptions.title")}
        description={t("subscriptions.sub_title")}
        button={
          userCan("subscription_create") ? (
            <Button
              disabled={status !== "succeeded"}
              href={"/subscriptions"}
              icon={<PlusIcon />}
              variant={"secondary"}
            >
              {t("subscriptions.add_plan")}
            </Button>
          ) : null
        }
      />
      {status === "loading" && <LoadingStates count={2} height={60} />}
      {status === "succeeded" &&
        subscriptions &&
        subscriptions.map((subscription) => {
          return (
            <ActionTile
              key={subscription.uuid}
              title={subscriptionTitle(subscription)}
              description={subscriptionDescription(subscription)}
              editLink={
                subscription.status === "active" &&
                userCan("subscription_update")
                  ? "/subscriptions/" + subscription.uuid
                  : null
              }
              deleteLink={
                subscription.status === "active" &&
                userCan("subscription_cancel")
                  ? "/subscriptions/" + subscription.uuid + "/cancel"
                  : null
              }
              tag={subscriptionTag(subscription)}
            />
          );
        })}
    </>
  );
};

export default Subscriptions;
