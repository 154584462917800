import { IntervalType, PlanTerm } from "../utils/types";
import { useTranslation } from "react-i18next";

const useFormats = () => {
  const { t } = useTranslation();

  const planTypeName = (planType: IntervalType) => {
    switch (planType) {
      case "month":
        return t("common.dates.monthly");
      case "year":
        return t("common.dates.yearly");
      default:
        return t("common.dates.daily");
    }
  };

  const planTermsPeriod = (terms: PlanTerm) => {
    switch (terms.interval_type) {
      case "month":
        return terms.interval_count === 1
          ? t("common.dates.month")
          : `${terms.interval_count} ${t("common.dates.months")}`;
      case "year":
        return terms.interval_count === 1
          ? t("common.dates.year")
          : `${terms.interval_count} ${t("common.dates.years")}`;
      default:
        return terms.interval_count === 1
          ? t("common.dates.day")
          : `${terms.interval_count} ${t("common.dates.days")}`;
    }
  };

  const planTermsBillingInterval = (terms: PlanTerm) => {
    if (terms.interval_count === 1) return planTypeName(terms.interval_type);
    return `${t("subscriptions.descriptions.every")} ${planTermsPeriod(terms)}`;
  };

  const sentenceCase = (str: string) => {
    if (str.length === 0) {
      return str;
    }
    return str[0].toUpperCase() + str.substr(1);
  };

  return {
    planTermsBillingInterval,
    planTermsPeriod,
    planTypeName,
    sentenceCase,
  };
};

export default useFormats;
