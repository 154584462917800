import FenerumLogo from "./FenerumLogo";
import { useTranslation } from "react-i18next";

const PoweredBy = () => {
  const { t } = useTranslation();
  return (
    <div className="flex text-sm mt-4 items-center justify-center text-gray-400">
      <span className={"mr-1"}>{t("powered_by")}</span>
      <a href="https://www.fenerum.com" target={"_blank"}>
        <FenerumLogo />
      </a>
    </div>
  );
};

export default PoweredBy;
