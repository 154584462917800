import { PaymentCardData } from "../store/reducers/paymentMethods/paymentMethodsSlice";
import useCardFormat from "./useCardFormat";
import useFormats from "./useFormats";
import { useTranslation } from "react-i18next";

const usePaymentMethodFormat = () => {
  const { t } = useTranslation();
  const { lastDigitsCardNumber, cardExpiry } = useCardFormat();
  const { sentenceCase } = useFormats();
  const description = (paymentMethod: PaymentCardData) =>
    paymentMethod.active ? cardExpiry(paymentMethod) : t("payment.deactivated");

  const title = (paymentMethod: PaymentCardData) =>
    sentenceCase(paymentMethod.brand) +
    ` ${t("payment.ending_with")} ` +
    lastDigitsCardNumber(paymentMethod);

  return {
    description,
    title,
  };
};

export default usePaymentMethodFormat;
