import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(
    resourcesToBackend(
      (lang: string = "common", ns: string) =>
        import(`./locales/${lang}/${ns}.json`)
    )
  )
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultNS: "common",
    debug: process.env.NODE_ENV === "development",
    fallbackLng: ["en", "da", "nb"],
    // da-DK, nb-NO, en-GB are not supported by i18next
    load: "languageOnly",
    cache: {
      enabled: true,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
