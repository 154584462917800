import GuestLayout from "../layout/GuestLayout";
import { PageTitle, Paragraph } from "../ui/Fonts";
import Button from "../ui/Button";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <GuestLayout disableMiddleware={true} hide={["logo"]}>
      <div className={"text-center"}>
        <PageTitle>{t("404.title")}</PageTitle>
        <Paragraph className={"max-w-xs mx-auto"}>
          {t("404.description")}
        </Paragraph>
        <Button href={"https://www.fenerum.com"} block={true}>
          {t("404.button")}
        </Button>
      </div>
    </GuestLayout>
  );
};

export default NotFound;
