import Popup from "../../layout/Popup";
import { HorizontalRule, PageTitle, Paragraph } from "../../ui/Fonts";
import EditAccountForm from "../../layout/forms/EditAccountForm";
import { useTranslation } from "react-i18next";

const EditAccount = () => {
  const { t } = useTranslation();
  return (
    <Popup>
      <PageTitle>{t("account.edit")}</PageTitle>
      <Paragraph>{t("account.edit_details")}</Paragraph>
      <HorizontalRule />
      <EditAccountForm />
    </Popup>
  );
};

export default EditAccount;
