import Button from "./Button";
import PlusIcon from "./icons/PlusIcon";
import ActionPanel from "./ActionPanel";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  fetchPaymentCards,
  getPaymentCardsSortedByActive,
} from "../../store/reducers/paymentMethods/paymentMethodsSlice";
import LoadingStates from "./LoadingStates";
import ActionTile from "./ActionTile";
import PaymentCard from "./icons/PaymentCard";
import usePaymentMethodFormat from "../../hooks/usePaymentMethodFormat";
import {
  getPermissions,
  getStripeToken,
} from "../../store/reducers/auth/authSlice";
import usePermissions from "../../hooks/usePermissions";
import { useTranslation } from "react-i18next";

const PaymentMethods = () => {
  const dispatch = useAppDispatch();
  const permissions = useAppSelector(getPermissions);
  const { t } = useTranslation();
  const { userCan } = usePermissions(permissions);
  const paymentMethods = useAppSelector(getPaymentCardsSortedByActive);
  const { description, title } = usePaymentMethodFormat();
  const status = useAppSelector((state) => state.paymentMethods.status);
  const stripeToken = useAppSelector(getStripeToken);

  useEffect(() => {
    if (!paymentMethods) {
      dispatch<any>(fetchPaymentCards());
    }
  }, [dispatch, paymentMethods]);
  return (
    <>
      <ActionPanel
        title={t("payment_methods.title")}
        description={t("payment_methods.sub_title")}
        button={
          userCan("payment_card_create") && stripeToken ? (
            <Button
              disabled={status !== "succeeded"}
              href={"/payment-methods/"}
              icon={<PlusIcon />}
              variant={"secondary"}
            >
              {t("payment_methods.add_payment_method")}
            </Button>
          ) : null
        }
      />
      {status === "loading" && <LoadingStates count={2} height={60} />}
      {status === "succeeded" &&
        paymentMethods &&
        paymentMethods.map((paymentMethod) => {
          return (
            <ActionTile
              key={paymentMethod.uuid}
              icon={<PaymentCard name={paymentMethod.brand} />}
              description={description(paymentMethod)}
              descriptionColor={paymentMethod.active ? null : "red"}
              title={title(paymentMethod)}
              deleteLink={
                paymentMethod.active && userCan("payment_card_deactivate")
                  ? `/payment-methods/${paymentMethod.uuid}/disable/`
                  : null
              }
            />
          );
        })}
    </>
  );
};

export default PaymentMethods;
