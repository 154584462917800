import { SubscriptionData } from "../store/reducers/subscriptions/subscriptionSlice";
import { useMoneyFormat } from "./useMoneyFormat";
import useDateFormat from "./useDateFormat";
import useFormats from "./useFormats";
import { TagProps } from "../components/ui/Tag";
import { useTranslation } from "react-i18next";

const useSubscriptionFormat = () => {
  const { t } = useTranslation();
  const { formatMoney } = useMoneyFormat();
  const { formatDate } = useDateFormat();
  const { planTermsBillingInterval, planTermsPeriod } = useFormats();

  const subscriptionTitle = (subscription: SubscriptionData) => {
    return (
      subscription.quantity +
      "x " +
      subscription.plan.name +
      " - " +
      formatMoney(
        parseFloat(subscription.terms.price),
        subscription.terms.currency
      ) +
      " / " +
      planTermsPeriod(subscription.terms)
    );
  };

  const subscriptionDescription = (subscription: SubscriptionData) => {
    if (subscription.status === "not_starting")
      return `${t("subscriptions.descriptions.not_starting")} ${formatDate(
        subscription.next_renewal_date
      )}`;

    if (subscription.status === "not_renewing")
      return `${t("subscriptions.descriptions.not_renewing")}  ${formatDate(
        subscription.next_renewal_date
      )}`;

    if (subscription.status === "expired")
      return `${t("subscriptions.descriptions.expired")}  ${formatDate(
        subscription.next_renewal_date
      )}`;

    if (subscription.status === "scheduled")
      return `${t("subscriptions.descriptions.scheduled")}  ${formatDate(
        subscription.next_renewal_date
      )}`;

    return `${t(
      "subscriptions.descriptions.billed"
    )}  ${planTermsBillingInterval(subscription.terms).toLowerCase()} - ${t(
      "subscriptions.descriptions.next_renew"
    )}  ${formatDate(subscription.next_renewal_date)}`;
  };

  const subscriptionTag = (subscription: SubscriptionData) => {
    let tagProps: TagProps;
    switch (subscription.status) {
      case "active":
        tagProps = {
          color: "green",
          text: t("subscriptions.statuses.active"),
        };
        break;
      case "scheduled":
        tagProps = {
          color: "yellow",
          text: t("subscriptions.statuses.scheduled"),
        };
        break;
      case "not_renewing":
        tagProps = {
          color: "blue",
          text: t("subscriptions.statuses.not_renewing"),
        };
        break;
      case "not_starting":
        tagProps = {
          color: "blue",
          text: t("subscriptions.statuses.not_starting"),
        };
        break;
      case "expired":
        tagProps = {
          color: "red",
          text: t("subscriptions.statuses.expired"),
        };
        break;
    }

    return tagProps as TagProps;
  };

  return {
    subscriptionTitle,
    subscriptionDescription,
    subscriptionTag,
  };
};

export default useSubscriptionFormat;
