import Popup from "../../layout/Popup";
import { HorizontalRule, PageTitle } from "../../ui/Fonts";
import { useTranslation } from "react-i18next";

const EditPaymentMethod = () => {
  const { t } = useTranslation();
  return (
    <Popup>
      <PageTitle>{t("payment_methods.edit_payment_method")}</PageTitle>
      <HorizontalRule />
    </Popup>
  );
};

export default EditPaymentMethod;
